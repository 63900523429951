/**
 * Author: rinaldiguarsa
 * Created On: 12/31/18
 * Project: tado-tv-player
 */

import React, {Component} from 'react';
import {axiosClientTadoPlayer} from "../utils/axiosClient";
import {BrowserDetect,insideIframe,convertToObject} from '../utils/utils';
import MobileDetect from '../../node_modules/mobile-detect/';
import './Player.less'
import LandingPage from './LandingPage';
import MobilePlayer from './MobilePlayer';
import WebPlayer from './WebPlayer';

class Player extends Component {
    constructor(props) {
        super(props);
        const { match: { params } } = props;
        let forceMobile = false;
        let client_id = null;
        let noredirect = false;
        let sendp = false;
        let nofs = false;
        let showRotate = false;
        let redirectTo = null;
        if(this.props.location.search!==undefined){
            const qs = convertToObject(this.props.location.search);
            forceMobile = (qs.forceMobile!==undefined);
            client_id = (qs.client_name!==undefined)?qs.client_name:null;
            noredirect = (qs.noredirect!==undefined);
            sendp = (qs.sendp!==undefined);
            nofs = (qs.nofs!==undefined);
            showRotate = (qs.showRotate!==undefined);
            redirectTo = (qs.redirectTo!==undefined)?qs.redirectTo:null;
        }

        this.state = {
            busy: true,
            showBlank:false,
            slug: params.slug,
            md : new MobileDetect(window.navigator.userAgent),
            browserDetect : BrowserDetect(),
            inFrame : insideIframe(),
            title:"",
            synopsis:"",
            analytic:false,
            is_series:false,
            genre:"",
            artwork:{
                small:"",
                large:""
            },
            thumbnail: {
                mobile: null,
                desktop: null
            },
            series_name:"",
            whiteLabel:false,
            forceMobile:forceMobile,
            client_id:client_id,
            noredirect:noredirect,
            sendp:sendp,
            nofs:nofs,
            showRotate:showRotate,
            redirectTo
        }
    }

    componentDidMount() {
        const client_id = (this.state.client_id!==null)?'?client_name='+this.state.client_id:'';
        axiosClientTadoPlayer['get']('mobileweb/v1.6/movie/title/' + this.state.slug+client_id)
            .then(response => {
                this.setState({
                    ...response.data.data,
                    busy:false,
                    whiteLabel:response.data.data.thumbnail.desktop!==null
                })
            }).catch(error => {
            this.setState({showBlank:true});
            this.setState({busy: false})
        })
    }

    render() {
        if(!this.state.busy){
            if(this.state.md.mobile()!==null || this.state.forceMobile){
                return (

                    <MobilePlayer clientId={this.state.client_id} forceMobile={this.state.forceMobile} inFrame={this.state.inFrame} analytic={this.state.analytic} whiteLabel={this.state.whiteLabel} mobileDetect={this.state.md}
                                  browserDetect={this.state.browserDetect} slug={this.state.slug}
                                  noredirect={this.state.noredirect} sendp={this.state.sendp}
                                  nofs={this.state.nofs} showRotate={this.state.showRotate}
                                  redirectTo={this.state.redirectTo}
                    />
                )
            }else{
                if(this.state.inFrame || this.state.thumbnail.desktop!=null){
                    return (
                        <WebPlayer clientId={this.state.client_id} analytic={this.state.analytic} whiteLabel={this.state.whiteLabel} browserDetect={this.state.browserDetect} mobileDetect={this.state.md} slug={this.state.slug} inFrame={this.state.inFrame} sendp={this.state.sendp}/>
                    )
                }else{
                    return (
                        <LandingPage
                            slug={this.state.slug}
                            title={this.state.title}
                            series_name={this.state.series_name}
                            synopsis={this.state.synopsis}
                            artwork={this.state.artwork.small}
                        />
                    )
                }
            }
        }else{
            return (<div className="loader-container">
                <div className="spinners-container">
                    <div className="spinner-block">
                        <div className="spinner-eff spinner-eff-4">
                            <div className="bar bar-top"></div>
                            <div className="bar bar-right"></div>
                            <div className="bar bar-bottom"></div>
                            <div className="bar bar-left"></div>
                        </div>
                        <span>Loading</span>
                    </div>
                </div>
            </div>);
        }


    }
}

export default Player;
