import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import CONFIG from './constants/config';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
Sentry.init({dsn: CONFIG.sentry_dsn});
ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
