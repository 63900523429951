import {getTimeUtc, logger, mapDimensionQuality, mapIndexByQualityLabel} from "./utils";
import Geocoding from "./Geocoding";
import md5 from "js-md5";
import _ from "lodash";
import Tracking from "./Tracking"
import {axiosClientTadoPlayer} from "./axiosClient";
import {injectFirefoxStyleInputRange,getStyleChangeBGInputRange} from "./styleHelper";
import VTTConverter from "srt-webvtt";
const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;
const initialMovieBar = 339.292;

class PlayerHelper{

    constructor(){
        this.elmSelector = null;
        this.tracking  = new Tracking(true);
        this.player = null;
        this.isMobile = false;
        this.browserDetect = "";
        this.orientation = 'potrait';
        this.inFrame = false;
        this.isMoviePotrait = true;
    }

    init(player,isMobile,browserDetect,inFrame = false){
        this.player = player;
        this.isMobile = isMobile;
        this.browserDetect = browserDetect;
        this.inFrame = inFrame;
        this.setElmSelector();
    }

    setElmSelector(){
        this.elmSelector = {
            wrapper : document.getElementById('wrapper'),
            videoControl : document.getElementById(this.isMobile?'video-control':'new-video-control'),
            settingControl : document.getElementById('setting-control'),
            settingContent : document.querySelectorAll('.setting-content'),
            volumeBar : document.querySelector('.vol .volume-bar'),
            barBridge : document.getElementById('bar-fill'),
            barMovie : document.querySelector('.progress__value'),
            playerClip : document.getElementById('my-video'),
            playerBridge : document.getElementById('my-videobridge'),
            bottomControl : document.getElementById('bottom-control'),
        }
    }

    forceHorizontal(){
        this.elmSelector.playerClip.classList.add('force-horizontal');
        this.elmSelector.playerBridge.classList.add('force-horizontal');
    }

    forceFullWidth(){
        if(this.elmSelector.playerClip.classList.contains('horizontal-in-middle')){
            this.elmSelector.playerClip.classList.remove('horizontal-in-middle');
        }
        if(this.elmSelector.playerBridge.classList.contains('horizontal-in-middle')){
            this.elmSelector.playerBridge.classList.remove('horizontal-in-middle');
        }
        this.elmSelector.playerClip.classList.add('full-width');
        this.elmSelector.playerBridge.classList.add('full-width');
    }

    forcePortrait(){
        if(this.elmSelector.playerClip.classList.contains('full-width')){
            this.elmSelector.playerClip.classList.remove('full-width');
        }
        if(this.elmSelector.playerBridge.classList.contains('full-width')){
            this.elmSelector.playerBridge.classList.remove('full-width');
        }
        if(this.elmSelector.playerClip.classList.contains('horizontal-in-middle')){
            this.elmSelector.playerClip.classList.remove('horizontal-in-middle');
        }
        if(this.elmSelector.playerBridge.classList.contains('horizontal-in-middle')){
            this.elmSelector.playerBridge.classList.remove('horizontal-in-middle');
        }
    }

    forceLandscape(is_potrait){
        if(this.inFrame && !is_potrait){
            document.getElementById('mobile-player').classList.add('landscape');
        }
    }

    horizontalInTheMiddle(){
        if(this.elmSelector.playerClip.classList.contains('full-width')){
            this.elmSelector.playerClip.classList.remove('full-width');
        }
        if(this.elmSelector.playerBridge.classList.contains('full-width')){
            this.elmSelector.playerBridge.classList.remove('full-width');
        }
        this.elmSelector.playerClip.classList.add('horizontal-in-middle');
        this.elmSelector.playerBridge.classList.add('horizontal-in-middle');
    }

    changeWidthVideo(is_potrait,orientation){
        if(is_potrait && orientation==='landscape'){
            this.forcePortrait();
        }else if(!is_potrait && orientation==='potrait'){
            this.horizontalInTheMiddle();
        }else{
            this.forceFullWidth();
        }
    }

    //Movie Data Function Helper
    platformInit(callback1,callback2){
        const geocode = new Geocoding();
        geocode.getCountryId().then((countryId)=>{
            if(countryId!==null){
                if(countryId!=='ID' && countryId!=='EN'){
                    countryId = 'EN';
                }
            }
            logger('Set Country ID default',countryId);
            callback1(countryId);
            this.initData(callback2);
        })
    }
    async initData(callback){
        let token = localStorage.getItem('tp-token');
        if(token===null){
            const cid = md5(window.navigator.userAgent+'|'+(new Date().getTime()));
            let response = await this.tracking.platformInit(cid);
            token = response.token;
        }
        logger('Get Token',token);
        callback(token);

    }

    getMovieDetail(lang,slug,client_id=null) {
        const token = localStorage.getItem('tp-token');
        axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = token;
        axiosClientTadoPlayer.defaults.headers.common['lang'] = lang;
        client_id = client_id!=null && client_id!==''?'?client_name='+client_id:'';
        return axiosClientTadoPlayer['get']('mobileweb/v1.6/movie/detail/' + slug+client_id).then(response=>{
            return this.compactResultMovieDetail(response,slug,token);
        });
    }

    async compactResultMovieDetail(movieData,slug,token){
        movieData.data.data.watch_uid = await this.getWatchUid(slug,token);
        return movieData;
    }

    getWatchUid(slug,token) {
        axiosClientTadoPlayer.defaults.headers.common['x-access-token'] = token;
        return axiosClientTadoPlayer['get']('mobileweb/v1.6/movie/watch-uid/' + slug).then(response=>{
            return response.data.data.watch_uid;
        });
    }

    //Video Style Helper
    switchPlayer(type="bridge"){
        if(type==="bridge"){
            if(this.isMobile){
                this.elmSelector.playerClip.style.zIndex = 0;
                this.elmSelector.playerBridge.style.zIndex = 1;
            }else {
                this.elmSelector.playerClip.style.display = 'none';
                this.elmSelector.playerBridge.style.display = 'flex';
            }
        }else{
            if(this.isMobile) {
                this.elmSelector.playerClip.style.zIndex = 1;
                this.elmSelector.playerBridge.style.zIndex = 0;
            }else{
                this.elmSelector.playerClip.style.display = 'flex';
                this.elmSelector.playerBridge.style.display = 'none';
            }
        }
    }

    setBgControl(state){
        if(state){
            if(this.elmSelector.bottomControl.classList.contains('control-bg-none')){
                this.elmSelector.bottomControl.classList.remove('control-bg-none');
            }
            this.elmSelector.bottomControl.classList.add('control-bg-black');
        }else{
            if(this.elmSelector.bottomControl.classList.contains('control-bg-black')){
                this.elmSelector.bottomControl.classList.remove('control-bg-black');
            }
            this.elmSelector.bottomControl.classList.add('control-bg-none');
        }
    }

    toggleVideoControl(state = true){
        const showClass = 'slideup-video-control';
        const hideClass = 'slidedown-video-control';
        if(!this.isMobile){
            this.setBgControl(state);
        }
        if(state){
            if(this.elmSelector.videoControl.classList.contains(hideClass)){
                this.elmSelector.videoControl.classList.remove(hideClass);
            }
            this.elmSelector.videoControl.classList.add(showClass);
        }else{
            if(this.elmSelector.videoControl.classList.contains(showClass)){
                this.elmSelector.videoControl.classList.remove(showClass);
            }
            this.elmSelector.videoControl.classList.add(hideClass);
        }
    }

    toggleSettingContainer(state = true){
        let heightSetting = state?'auto':'0';
        if(state){
            this.elmSelector.settingControl.style.height = '20%';
        }else{
            this.elmSelector.settingControl.style.height = '0%';
        }
        this.elmSelector.settingControl.style.transition = 'all 0.2s';
        let settingContentLength = this.elmSelector.settingContent.length;
        for(let i=0;i<settingContentLength;i++){
            this.elmSelector.settingContent[i].style.height = heightSetting;
            this.elmSelector.settingContent[i].style.transition = 'all 0.2s';
        }
    }

    toggleSplash(is_mobile = false,orientation = 'landscape',action=''){
        if(is_mobile && orientation==='landscape'){
            if(this.elmSelector.videoControl.classList.contains('slidedown-video-control')){
                document.querySelector('.action-text-outer span').innerHTML = action;
                document.querySelector('.action-text-outer span').classList.add('animate');
                setTimeout(()=>{
                    document.querySelector('.action-text-outer span').classList.remove('animate');
                    document.querySelector('.action-text-outer span').innerHTML = '';
                },500);
            }else{
                document.querySelector('.action-text span').innerHTML = action;
                document.querySelector('.action-text span').classList.add('animate');
                setTimeout(()=>{
                    document.querySelector('.action-text span').classList.remove('animate');
                    document.querySelector('.action-text span').innerHTML = '';
                },500);
            }
        }else{
            document.querySelector('.splash img').classList.add('animate');
            setTimeout(()=>{
                document.querySelector('.splash img').classList.remove('animate');
            },500);
        }

    }

    resetBarMovie(){
        this.elmSelector.barMovie.style.strokeDashoffset = initialMovieBar;
    }
    resetBarBridge(){
        this.elmSelector.barBridge.style.width = '0%';
    }

    changeBarMovie(percentage){
        if(percentage>100){
            this.elmSelector.barMovie.style.strokeDashoffset = initialMovieBar;
        }else{
            const progress = percentage / 100;
            this.elmSelector.barMovie.style.strokeDashoffset = CIRCUMFERENCE * (1 - progress);
        }
    }

    changeBarBridge(percentage){
        if(percentage>100){
            this.elmSelector.barBridge.style.width = '100%';
        }else{
            this.elmSelector.barBridge.style.width = percentage+'%';
        }
    }

    adjustFullscreen(isFullscreen,isMobile = false,player,qualityLevel = '',browserDetect = '',inFrame = false,is_movie_potrait = true){
        if(!isFullscreen){
            this.elmSelector.wrapper.style.position='none';
            this.elmSelector.wrapper.style.left=0;
            this.elmSelector.wrapper.style.right=0;
            if(isMobile && !inFrame)this.adjustObjectFit(qualityLevel,browserDetect);
            if (player.exitFullscreen && document.fullscreenElement!==null) {
                player.exitFullscreen();
            } else if (player.mozExitFullScreen && document.mozFullScreen) {
                player.mozExitFullScreen();
            } else if (player.webkitExitFullscreen && document.webkitIsFullScreen) {
                player.webkitExitFullscreen();
            }
            if(this.isMobile){
                // eslint-disable-next-line
                screen.orientation.unlock();
            }
        }else{
            this.elmSelector.wrapper.style.position='absolute';
            this.elmSelector.wrapper.style.top=0;
            this.elmSelector.wrapper.style.left=0;
            this.elmSelector.wrapper.style.right=0;
            // if(!isMobile)document.querySelector('.vjs-tech').style.objectFit = 'cover';
            if (document.getElementById('wrapper').requestFullscreen ) {
                document.getElementById('wrapper').requestFullscreen();
            } else if (document.getElementById('wrapper').mozRequestFullScreen) {
                document.getElementById('wrapper').mozRequestFullScreen();
            } else if (document.getElementById('wrapper').webkitRequestFullscreen) {
                document.getElementById('wrapper').webkitRequestFullscreen();
            }
            if(!is_movie_potrait && this.isMobile){
                // eslint-disable-next-line
                screen.orientation.lock('landscape');
            }else if(is_movie_potrait && this.isMobile){
                // eslint-disable-next-line
                screen.orientation.lock('portrait');
            }
        }
    }

    adjustObjectFit(qualityLevel,browserDetect){
        if (qualityLevel === 'low' && browserDetect !== 'Webkit') {
            document.querySelector('.vjs-tech').style.objectFit = 'cover';
        } else if(!this.isMobile){
            document.querySelector('.vjs-tech').style.objectFit = 'contain';
        }
    }

    addStyleVolumeBar(value,isMobile,browserDetect){
        if(!isMobile && browserDetect==='Firefox'){
            injectFirefoxStyleInputRange(value);
        }else{
            this.elmSelector.volumeBar.style.backgroundImage = getStyleChangeBGInputRange(value);
        }
    }

    //Subtitle Video Helper
    clearTextTrack(player){
        let length = player.textTracks().length;
        for(let i=0;i<length;i++){
            if(player.textTracks()[i]!==undefined){
                player.removeRemoteTextTrack(player.textTracks()[i]);
            }
        }
    }

    reAssignTrackElement(){
        if(document.getElementById('track-subtitle')!==null){
            let trackEl = document.createElement("TRACK");
            trackEl.srclang = "";
            trackEl.kind = "captions";
            trackEl.src = "";
            trackEl.label = "";
            trackEl.id = "track-subtitle";
            document.getElementsByTagName('video')[0].appendChild(trackEl);
        }
    }

    fetchSubtitle(langId,fileUrl,player,currentIndexMovie,callback){
        fetch(fileUrl)
        .then(response => {
            let blobPromise = response.blob();
            blobPromise.then(myBlob => {
                const vttConverter = new VTTConverter(myBlob);
                vttConverter
                .getURL()
                .then(url => { // Its a valid url that can be used further
                    this.clearTextTrack(player);
                    player.addRemoteTextTrack({kind: 'captions',
                        label:langId+currentIndexMovie,
                        src: url }, false);
                    setTimeout(()=>{
                        const  length = player.textTracks().length;
                        player.textTracks()[length-1].mode = 'showing';
                        callback(langId);
                    },500)
                })
                .catch(function(err) {
                    logger('fetch VTT Converter',err,'error');
                })
            });
        })
    }
    readOrientation(){
        const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        if("orientation" in window){
            const angle = Math.abs(parseInt(window.orientation));
            if(angle===90){
                this.orientation = 'landscape';
            }else{
                this.orientation = 'potrait';
            }
        }else if(w>h){
            this.orientation = 'landscape';
        }else{
            this.orientation = 'potrait';
        }
        return this.orientation;
    }

    showLoading(msg=''){
        const loading = document.querySelector('.loading-video');
        loading.style.display='block';
        if(msg!==''){
            document.querySelector('.loading-video .loading-text').innerHTML = msg;
        }
    }
    hideLoading(){
        const loading = document.querySelector('.loading-video');
        loading.style.display='none';
    }

    composeQualityLevels(qualityLevels) {
        logger('composer qualityLevels', qualityLevels)
        let newQualityLevels = {};
        const originLength = qualityLevels.length;
        if (originLength>0){
            const minusOne = 1;
            const length = parseInt(originLength) - minusOne;
            const breakLoop = (length - 3)<0?0:length - 3;
            let index = length > 3 ? 3 : length;

            for (let i = length; i >= breakLoop; i--) {
                const dimensionToCompare = this.isMoviePotrait ? qualityLevels[i].width : qualityLevels[i].height;
                newQualityLevels[this.getQualityLabelByIndex(index)] = dimensionToCompare;
                index--;
            }
            const tempNewQuality = newQualityLevels;
            newQualityLevels = {};
            _.eachRight(tempNewQuality, (opt, idx) => {
                newQualityLevels[idx] = opt;
            });
        }
        return newQualityLevels;
    }

    getQualityLabelByWidth(width){
        switch (width){
            case 240:
                return 'low';
            case 360:
                return 'basic';
            case 480:
                return 'high';
            case 720:
                return 'ultra';
        }
    }
    getQualityLabelByIndex(index){
        switch (index){
            case 0:
                return 'low';
            case 1:
                return 'basic';
            case 2:
                return 'high';
            case 3:
                return 'ultra';
        }
    }

    setQualityLevel(qualityLevelObject,qualityLevelLabel){
        const qualityWidth = mapDimensionQuality(qualityLevelLabel);
        logger('quality level list',qualityLevelObject);
        logger('set quality level dimension',qualityWidth);
        logger('set quality level selected before',qualityLevelObject.selectedIndex);
        const originLength = qualityLevelObject.length;
        if(originLength>0){
            const minusOne = 1;
            const length = parseInt(originLength) - minusOne;
            const breakLoop = (length - 3)<0?0:length - 3;
            let index = length > 3 ? 3 : length;
            const indexQuality = mapIndexByQualityLabel(qualityLevelLabel);
            if(length>3){
                for(let y=0;y<breakLoop;y++){
                    let qualityLevel = qualityLevelObject[y];
                    qualityLevel.enabled = false;
                    logger('set another quality level disable',y);
                }
            }
            for (let i = length; i >=breakLoop; i--) {
                let qualityLevel = qualityLevelObject[i];
                if (indexQuality===index || indexQuality===-1) {
                    qualityLevel.enabled = true;
                    logger('set quality level enable',indexQuality);
                } else {
                    qualityLevel.enabled = false;
                    logger('set quality level disable',indexQuality);
                }
                index--;
            }

            setTimeout(()=>{
                logger('set quality level selected after',qualityLevelObject.selectedIndex);
            },500);
        }
    }

    initWatchTimer(_this){
        _this.timeSecond = 0;
        _this.timeMili = 0;
        _this.timeMiliCounter = 0;
        // this.putDataTrackWatchTime(movie_id,plot_id,_this.timeSecond);
    }

    initIntervalWatchTimer(_this,callback = null){
        _this.sendWatchTime = setInterval(()=>{
            if(callback!=null){
                this.sendWatchTimer(_this,callback);
            }
        },30000);
    }

    sendWatchTimer(_this,callback = null){
        let dataWatch = localStorage.getItem('watchTrack');
        let dataSend = [];
        if(dataWatch!=null){
            dataWatch = JSON.parse(dataWatch);
            dataWatch.forEach((item,index)=>{
                const time = parseInt(item.time);
                if(time!==0){
                    dataSend.push({
                        plot_id:item.plot_id,
                        watch_uid:item.watch_uid,
                        duration:time,
                        event_time:getTimeUtc().toString()
                    });
                }
            });
            // console.log('send watch time data',dataSend);
            this.resetWatchTimerData(_this);
            if(callback!=null && dataSend.length>0){
                callback(dataSend);
            }
        }
    }

    sendWatchTimerFallback(callback = null){
        let dataWatch = localStorage.getItem('watchTrackFallback');
        if(dataWatch!=null){
            const dataSend = JSON.parse(dataWatch);
            // console.log('send watch time data fallback',dataSend);
            if(callback!=null && dataSend.length>0){
                localStorage.removeItem('watchTrackFallback');
                callback(dataSend);
            }
        }
    }

    buildDataSendWatchTracker(){
        let dataWatch = localStorage.getItem('watchTrack');
        let dataSend = [];
        if(dataWatch!=null){
            dataWatch = JSON.parse(dataWatch);
            dataWatch.forEach((item,index)=>{
                const time = parseInt(item.time);
                if(time!==0){
                    dataSend.push({
                        plot_id:item.plot_id,
                        watch_uid:item.watch_uid,
                        duration:time,
                        event_time:getTimeUtc().toString()
                    });
                }
            });
        }
        return dataSend;
    }

    resetWatchTimerData(_this){
        this.resetWatchTimer(_this);
        localStorage.removeItem('watchTrack');
    }

    resetWatchTimer(_this){
        _this.timeSecond = 0;
        _this.timeMili = 0;
        _this.timeMiliCounter = 0;
    }

    calculateWatchTimer(watch_uid,plot_id,currentTime,_this,callback = null){
        let splitTime =  currentTime.toString().split('.');
        let miliReal = parseFloat('0.'+splitTime[1]);
        const timeDiff = (miliReal-_this.timeMiliCounter);
        if(_this.timeMiliCounter>miliReal){
            _this.timeMiliCounter = 0;
            _this.timeSecond+=1;
            _this.timeMili = _this.timeMiliCounter;
            this.putDataTrackWatchTime(watch_uid,plot_id,_this.timeSecond);
        }
        _this.timeMili += (timeDiff>0?timeDiff:0);
        _this.timeMiliCounter = miliReal;
    }

    putDataTrackWatchTime(watch_uid,plot_id,second = 0){
        // console.log('put watch',`${plot_id} - ${second}`);
        let dataWatch = localStorage.getItem('watchTrack');
        if(dataWatch!=null){
            dataWatch = JSON.parse(dataWatch);
            const indexPlot = _.findIndex(dataWatch,{plot_id:plot_id});
            if(indexPlot>=0){
                dataWatch[indexPlot].time = parseInt(second);
                dataWatch[indexPlot].watch_uid = watch_uid;
                localStorage.setItem('watchTrack',JSON.stringify(dataWatch));
            }else{
                dataWatch.push(
                    {
                        plot_id : plot_id,
                        time:parseInt(second),
                        watch_uid:watch_uid,
                    }
                );
                localStorage.setItem('watchTrack',JSON.stringify(dataWatch));
            }
        }else{
            const dataInit = [
                    {
                        plot_id : plot_id,
                        time:parseInt(second),
                        watch_uid:watch_uid
                    }
                ];
            localStorage.setItem('watchTrack',JSON.stringify(dataInit));
        }
    }

    putDataTrackWatchTimeFallback(newData){
        // console.log('put watch send fallback',newData);
        let dataWatch = localStorage.getItem('watchTrackFallback');
        if(dataWatch!=null){
            dataWatch = JSON.parse(dataWatch);
            localStorage.setItem('watchTrackFallback',JSON.stringify([...dataWatch,...newData]));
        }else{
            localStorage.setItem('watchTrackFallback',JSON.stringify(newData));
        }
    }
}

export default PlayerHelper
