/**
 * Created by rinaldiguarsa on 11/10/18.
 */

import React, {Component} from 'react'
import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import CONFIG from '../constants/config';
import {
    mapTextQuality,
    mapTextSubtitle,
    vis,
    seekTime,
    idleTimeVideoControl,
    logger,
    voidBridgeTime,
    mapDimensionQuality
} from '../utils/utils'
import MetaTags from 'react-meta-tags';
import {defaultBannerState,generateDefaultStatePlayer,configPlayer} from "../utils/const";
import PausePoster from '../components/PausePoster';
import EndPoster from '../components/EndPoster';
import InitPosterMobile from "../components/InitPosterMobile";
import BridgeControl from "../components/BridgeControl";
import SettingContent from "../components/SettingContent";
import Share from "../components/Share";
import Banner from "../components/Banner"
import BannerVertical from "../components/BannerVertical"
import Lang from "../utils/Lang"
import Tracking from "../utils/Tracking"
import EpisodeList from '../components/EpisodeList'
import PlayerHelper from '../utils/PlayerHelper'
import LoadingVideo from '../components/loadingVideo'


import playButton from '../assets/images/tadoPlayer/play-button.png';
import pauseButton from '../assets/images/tadoPlayer/pause button.png';
import settingButton from '../assets/images/Web/Settings.png';
import fullscreenButton from '../assets/images/Web/Fullscreen.png';
import shareButton from '../assets/images/Web/Share.png';
import tadologoWhite from '../assets/images/tadoPlayer/tado-logo.png';
import ffCircleButton from '../assets/images/tadoPlayer/ff-10s.png'
import bwCircleButton from '../assets/images/tadoPlayer/back-10s.png'
import volumeMute from '../assets/images/Web/mute.png'
import volumeOn from '../assets/images/Web/loud.png'
import EpisodeListSmall from '../assets/images/Web/Button/Episode List.png'
import NextEpisodeSmall from '../assets/images/Web/Next-Episode.png'
import NextEpisodeDisSmall from '../assets/images/Web/Next-EpisodeDisabled.png'

import defaultPosterBlack from '../assets/images/blackbg.png';

//Splash
import playSplash from '../assets/images/tadoPlayer/Dekstop/Play-Splash.png'
import pauseSplash from '../assets/images/tadoPlayer/Dekstop/Pause-Splash.png'
import forwardSplash from '../assets/images/tadoPlayer/FF-10s-Splash.png'
import backwardSplash from '../assets/images/tadoPlayer/Rewind-10s-Splash.png'

import './MobilePlayer.less';

import videojs from 'video.js';
import videojslevel from '../../node_modules/videojs-contrib-quality-levels/dist/videojs-contrib-quality-levels';

import '../assets/css/video-js.less';
window.videojs = videojs;

window.vis = vis();
const tracking = new Tracking(true);
const playerHelper = new PlayerHelper();
const seekForward = seekTime;
const seekBackward = seekTime*-1;
const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
const h = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
const scale = h/w;
class MobilePlayer extends Component {
    constructor(props) {
        super(props);
        this.lang = new Lang();
        const initTranslate = this.lang.translate();
        this.noredirect = this.props.noredirect;
        this.redirectTo = this.props.redirectTo;
        const isIphone = (!this.props.forceMobile && this.props.mobileDetect.is('Iphone'));
        const tempState = generateDefaultStatePlayer(this.props.slug,this.props.whiteLabel,this.props.mobileDetect,
            this.props.browserDetect,this.props.analytic,playButton,volumeOn,null,playSplash,initTranslate.setting.automatic,
            initTranslate.setting.off,true,isIphone,initTranslate,this.props.inFrame,(this.props.clientId==='maxstream'?'Unmute':initTranslate.setting.sound_on),initTranslate.loading_text_start,this.props.clientId,this.props.sendp,this.props.nofs)
        this.defaultState = tempState;
        this.state = tempState;
        this.isMobileOrigin = this.props.mobileDetect.mobile()!=null;
        this.forceShowRotate = this.props.showRotate;
        this.lastCheckpoint = this.lastCheckpoint.bind(this);
        this.replayMovie = this.replayMovie.bind(this);
        this.handlerPlayPause = this.handlerPlayPause.bind(this);
        this.playNextMovie = this.playNextMovie.bind(this);
        this.handlerVideoControl = this.handlerVideoControl.bind(this);
        this.handlerFullscreen = this.handlerFullscreen.bind(this);
        this.handlerSettingControl = this.handlerSettingControl.bind(this);
        this.handlerShare = this.handlerShare.bind(this);
        this.handleSeekSidebarRight = this.handleSeekSidebarRight.bind(this);
        this.handleSeekSidebarLeft = this.handleSeekSidebarLeft.bind(this);
        this.handlerEpisodeList = this.handlerEpisodeList.bind(this);
        tracking.setAnalyticStatus(this.state.analytic);
        tracking.setInFrame(this.state.inFrame);
        this.clickedControl = false;
        this.checkVideoBuffer = {
            checkOne : false,
            checkTwo : false,
            checkThree : false,
            checkFour : false,
            checkFive : false
        };
        this.playerVolumeFadeBridge = 1.0;
        this.playerVolumeFadeClip = 1.0;
        playerHelper.initWatchTimer(this);
    }

    componentDidMount() {
        this.setState({
            busy: true,
            orientation:playerHelper.readOrientation()
        },()=>{
            playerHelper.platformInit((countryId)=>{
                this.setState({
                    defaultLang:countryId,
                    bridgeDefaultLang:countryId,
                    lang:this.lang.translate(countryId)
                })
            },(token)=>{
                tracking.setToken(token)
                this.getMovieDetail()
            });
            this.player = window.videojs('my-video',configPlayer);
            logger('player','Initialize player clip object');
            window.videojs.registerPlugin('qualityLevels',videojslevel);
            logger('player','Register Plugin Quality Levels');
            this.playerBridge = window.videojs('my-videobridge',configPlayer);
            logger('player','Initialize player bridge object');
            this.initialEventPlayer();
            playerHelper.init(this.player,this.state.isMobile,this.state.bd,this.state.inFrame);
            let _this = this;
            this.intervalBuffered = null;
            window.vis(function(){
                const player = _this.state.playerInfo.videoType==='bridge'?_this.playerBridge:_this.player;
                if(window.vis()){
                    setTimeout(function(){
                        if(!_this.state.playerInfo.hasEnded && !_this.state.elm.showPausePoster && _this.state.playerInfo.hasStart && !_this.state.elm.showBannerVertical){
                            player.play();
                            playerHelper.sendWatchTimerFallback((data)=>{
                                tracking.bulkWatchTime(data);
                            })
                        }
                    },1000);
                } else {
                    if(!_this.state.playerInfo.hasEnded && !_this.state.elm.showPausePoster && _this.state.playerInfo.hasStart){
                        player.pause();
                        const buildData = playerHelper.buildDataSendWatchTracker();
                        playerHelper.putDataTrackWatchTimeFallback(buildData);
                        playerHelper.resetWatchTimerData(_this);
                    }
                }
            });
            window.addEventListener('offline', (event)=>{
                const condition = navigator.onLine ? "online" : "offline";
                if(condition==='offline'){
                    playerHelper.sendWatchTimer(_this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        });
                    })
                }
            });
            window.addEventListener('online', (event)=>{
                const condition = navigator.onLine ? "online" : "offline";
                if(condition==='online'){
                    playerHelper.sendWatchTimerFallback((data)=>{
                        tracking.bulkWatchTime(data);
                    })
                }
            });
            if("onorientationchange" in window) {
                window.addEventListener("orientationchange", function() {
                    _this.setState({
                        orientation:playerHelper.readOrientation()
                    },()=>playerHelper.changeWidthVideo(_this.state.is_potrait,_this.state.orientation))
                });
            }else if("onresize" in window){
                window.addEventListener("resize", function() {
                    _this.setState({
                        orientation:playerHelper.readOrientation()
                    },()=>playerHelper.changeWidthVideo(_this.state.is_potrait,_this.state.orientation))
                });
            }
            if(this.state.isIphone){
                document.addEventListener('touchmove', function (event) {
                    event = event.originalEvent || event;
                    if (event.scale !== 1) { event.preventDefault(); }
                },  { passive: false });
            }
        })
        this.intervalPlayer = setInterval(()=>{
            if(this.state.playerInfo.hasStart && !this.state.playerInfo.hasEnded && !this.state.elm.showPausePoster){
                if(this.state.playerInfo.videoType==='bridge' && this.playerBridge.currentTime()>0){
                    playerHelper.switchPlayer('bridge');
                }else if(this.state.playerInfo.videoType==='movie' && this.player.currentTime()>0){
                    playerHelper.switchPlayer('clip');
                }
            }
        },50);
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({eventId});
        });
    }

    getMovieDetail(){
        playerHelper.getMovieDetail(this.state.defaultLang.toLowerCase(),this.state.slug,this.state.client_id).then(response => {
            this.setState({
                ...response.data.data,
                busy:false,
                loadingText:this.state.lang.general.loading_text_start,
                bridgeDefaultLang:(response.data.data.primary_lang!==null)?response.data.data.primary_lang:this.state.defaultLang
            },()=>{
                playerHelper.sendWatchTimerFallback((data)=>{
                    tracking.bulkWatchTime(data);
                })
                playerHelper.sendWatchTimer(this,(data)=>{
                    tracking.bulkWatchTime(data,(newData)=>{
                        playerHelper.putDataTrackWatchTimeFallback(newData);
                    })
                })
                playerHelper.isMoviePotrait = this.state.is_potrait;
                const forceLandscape = (this.isMobileOrigin)?this.state.is_potrait:true;
                playerHelper.forceLandscape(forceLandscape);
                if(this.state.is_series){
                    this.isLastEpisode();
                }
                tracking.pxTrackPageView();
                this.setElmState({
                    showAndroidImage: !this.state.isIphone,
                    showIphoneImage: this.state.isIphone,
                    showFullscreenButton: this.state.nofs===false?(!this.state.isIphone && this.state.allow_fullscreen):false
                },()=>{
                    logger('Set Initial source bridge player','');
                    this.playerBridge.src({
                        src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].bridge_url_resource.auto,
                        type: 'application/x-mpegURL'
                    });
                    this.setPlayerInfoState({
                        nextIndexMovie:_.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[this.state.playerInfo.currentIndexMovie].default_option})
                    },()=>{
                        playerHelper.changeWidthVideo(this.state.is_potrait,this.state.orientation);
                        playerHelper.switchPlayer('video');
                        this.setBannerMedia();
                        this.generateBridgeObject();
                        if((this.state.isMobile && this.state.isIphone) || (!this.state.isMobile && this.state.bd==='Webkit')) {
                            logger('Set clip source if iOS (mobile) or web with webkit engine ','');
                            this.player.src({
                                src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].url_resource.auto,
                                type: 'application/x-mpegURL'
                            });
                        }
                        try{
                            if(!this.state.isIphone){
                                document.getElementById('init-play').click();
                            }
                        }catch (e) {

                        }
                    });
                });
            })

        })
        .catch(error => {
            logger('Movie Not Found',this.state.slug,'error');
            this.setState({showBlank:true});
            this.setState({busy: false})
        })
    }

    initialPlay(){
        if(!this.state.busy){
            if(this.state.inFrame && this.state.isIphone && !this.noredirect){
                const client_id = (this.state.client_id!==null)?'?client_name='+this.state.client_id:'';
                let url = CONFIG.baseUrl+'watch/'+this.state.slug;
                if(this.redirectTo){
                    url = this.redirectTo;
                    const lastChar = this.redirectTo.substring(this.redirectTo.length - 1);
                    if(lastChar === '/'){
                        url += 'watch/'
                    }else{
                        url += '/watch/'
                    }
                    url += this.state.slug;
                }
                window.open(url+client_id,
                    '_blank'
                );
                return;
            }
            this.setElmState({
                showInitialPoster:false,
                showVideoContainer:true,
            },()=>{
                this.setPlayerInfoState({
                    hasStart:true
                },()=>{
                    tracking.pxTrackInitialPlay(this.state.title,this.state.series_name,this.state.genre);
                    const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
                    tracking.playVideo(this.state.moviePlot[this.state.playerInfo.currentIndexMovie].id,this.state.watch_uid,this.state.moviePlot[this.state.playerInfo.currentIndexMovie].metadata.is_endplot,is_firstplot);
                    const _this = this;
                    if(this.state.isMobile && !this.state.isIphone){
                        this.player.src({
                            src: this.state.moviePlot[this.state.playerInfo.currentIndexMovie].url_resource.auto,
                            type: 'application/x-mpegURL'
                        });
                        setTimeout(()=>{
                            _this.loadedDataClip();
                            const promise = this.player.play();
                            if (promise !== undefined) {
                                promise.then(function() {
                                }).catch(function(error) {
                                    _this.toggleMuteVolume(false);
                                    _this.player.play();
                                });
                            }
                        },1000)

                    }else{
                        let volumeText = this.state.playerInfo.muted?this.state.lang.setting.sound_off:this.state.lang.setting.sound_on;
                        if(this.state.client_id==='maxstream'){
                            volumeText = this.state.playerInfo.muted?'Mute':'Unmute';
                        }
                        this.setElmState({
                            volumeText:volumeText
                        },()=>{
                            this.playerBridge.volume(0);
                            this.playerBridge.play();
                            setTimeout(()=>{
                                _this.setPlayerInfoState({
                                    userGesture:true
                                },()=>{
                                    _this.loadedDataClip();
                                    this.playerBridge.muted(true);
                                    this.playerBridge.volume(1);
                                    this.playerBridge.pause(1);
                                    this.player.play();
                                })
                            },500)
                        })
                    }
                })
            });
        }
    }

    resetCheckVideoBuffer(){
        this.checkVideoBuffer = {
            checkOne : false,
            checkTwo : false,
            checkThree : false,
            checkFour : false,
            checkFive : false
        };
        this.playerVolumeFadeBridge = 1.0;
        this.playerVolumeFadeClip = 1.0;
    }
    changeCheckVideoBuffer(attr,value){
        this.checkVideoBuffer[attr] = value;
    }

    loadedDataClip(callback = null){
        this.setState({loadingText:this.state.lang.general.loading_text_start});
        if(this.state.playerInfo.hasStart && !this.state.elm.showPausePoster){
            const isFirstClip = this.state.playerInfo.currentIndexMovie===0;
            logger('Player Clip Load Metadata');
            let representations = this.state.moviePlot[this.state.playerInfo.currentIndexMovie].url_resource;
            if(!this.state.isIphone){
                representations = playerHelper.composeQualityLevels(this.player.qualityLevels());
            }
            logger('Player Clip Load Metadata: set representation',representations);
            logger('Player Clip Load Data','set duration and volume clip');
            logger('Player Clip Load Data: set Subtitle list option',this.state.moviePlot[this.state.playerInfo.currentIndexMovie].subtitle);
            this.setPlayerInfoState({
                duration:this.player.duration(),
                volume:this.player.volume(),
                clipState:'play',
                qualityLevelList:representations,
                subtitleList:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].subtitle,
                currentTime:0,
            },()=>{
                logger('Player Clip Load Data','set state show video control');
                this.setElmState({
                    showSettingContainer:true,
                    showVideoControl:true,
                    showBridgeControl:false,
                    showSettingButton:true,
                    showShareButton:true,
                    isOpenVideoControl:isFirstClip,
                    isOpenSettingControl:isFirstClip,
                    playPauseButton:pauseButton
                },()=>{
                    this.setVideoControlTimeout();
                    logger('Player Clip Load Data','change subtitle based on previous subtitle setting');
                    this.changeSubtitle(this.state.playerInfo.subtitle);
                    playerHelper.toggleSettingContainer(isFirstClip);
                    playerHelper.toggleVideoControl(isFirstClip);
                    if(callback!==null){
                        callback();
                    }
                });
            });
        }else if(this.state.elm.showPausePoster){
            logger('Player Clip Load Data','set subtitle clip when clip is paused');
            this.changeSubtitle(this.state.playerInfo.subtitle);
            if(callback!==null){
                callback();
            }
        }
    }

    eventEndedClip(){
        logger('Player Ended','');
        this.setElmState({
            isPause:false,
            showPausePoster:false,
            showEpisodeList:false
        },()=>{
            playerHelper.resetBarMovie();
            playerHelper.resetBarBridge();
            const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                });
            })
            if(playedMovie.bridge_url!==""){
                this.setPlayerInfoState({
                    videoType:'bridge',
                    currentTime:0
                }, () => {
                    if(this.state.playerInfo.currentIndexMovie === 0){
                        if(this.state.inFrame && this.state.sendp){
                            window.parent.postMessage("player:ended","*");
                        }
                    }
                    logger('Player Ended','play bridge after movie clip');
                    this.playerBridge.posterImage.hide();
                    this.playBridge()
                    this.hideAllSettingControl();
                    this.hideBanner();
                })
            }else{
                if(this.videoControlTimeoutObj!==null){
                    clearTimeout(this.videoControlTimeoutObj);
                }
                this.setPlayerInfoState({
                    hasEnded:true
                },()=>{

                    logger('Player Ended','End of Plot and show End Poster');
                    tracking.pxTrackCompleteMovie();
                    if(this.state.inFrame && this.state.sendp){
                        window.parent.postMessage("player:ended","*");
                    }
                    this.setElmState({
                        showEndPoster: true
                    },()=>{
                        this.setElmState({
                            showSettingContainer:true,
                            showVideoControl:false,
                            showSettingButton:false,
                            showShareButton:true,
                            isOpenVideoControl:false,
                            isOpenSettingControl:false
                        },()=>{
                            playerHelper.hideLoading();
                            this.hideAllSettingControl();
                            playerHelper.toggleSettingContainer(true);
                        });
                    });
                });
            }
        })
    }

    playerEventClip(){
        let _this = this;
        this.player.ready(function() {
            const qualityLevels = this.qualityLevels();
            qualityLevels.on('addqualitylevel', function(event) {
                if(!_this.state.isIphone){
                    logger('add quality level',event.qualityLevel);
                    const qualityLevelLabel = _this.getActualQualityLevel();
                    const qualityWidth = mapDimensionQuality(qualityLevelLabel);
                    const dimensionToCompare=_this.state.is_potrait?event.qualityLevel.width:event.qualityLevel.height;
                    if (dimensionToCompare === qualityWidth || qualityLevelLabel==='auto') {
                        event.qualityLevel.enabled = true;
                        logger('set quality level enable',dimensionToCompare);
                    } else {
                        event.qualityLevel.enabled = false;
                        logger('set quality level disable',dimensionToCompare);
                    }
                    if(_this.state.playerInfo.clipState==='play'){
                        const representations = playerHelper.composeQualityLevels(_this.player.qualityLevels());
                        _this.setPlayerInfoState({
                            qualityLevelList: representations
                        });
                    }
                }
            });
            //event on metadata loaded
            this.on('loadedmetadata',function(){
                _this.changeSubtitle(_this.state.playerInfo.subtitle);

            });
            this.on('loadstart',function(){});
            this.on('loadeddata',function(){
                logger('Player Clip Load Data','');
                _this.setState({busy:false});
            });
            this.on('ended',function(){
                _this.eventEndedClip();
            });
            this.on('timeupdate',function(){
                const currentTime = this.currentTime();
                const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];
                if(_this.state.playerInfo.clipState==='play'){
                    playerHelper.calculateWatchTimer(_this.state.watch_uid,moviePlayed.id,currentTime,_this);
                    window.parent.postMessage("player:timeupdate:" + currentTime,"*");
                }
                if(this.currentTime()>_this.state.playerInfo.currentTime && _this.state.isIphone && _this.state.playerInfo.changeQualityState){
                    _this.setPlayerInfoState({
                        changeQualityState:false,
                    },()=>{
                        playerHelper.hideLoading();
                        playerHelper.elmSelector.playerBridge.style.display = 'flex';
                        playerHelper.elmSelector.playerClip.style.display = 'flex';
                    });
                }
                if(this.currentTime()>0 && _this.state.playerInfo.clipState==='play' && _this.state.playerInfo.bridgeState==='play'){
                    const playedMovie = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];
                    if(playedMovie.bridge_url!=='') {
                        _this.setPlayerInfoState({
                            bridgeState: 'prebuffer'
                        }, () => {
                            setTimeout(()=>{
                                _this.playerBridge.src({
                                    src: playedMovie.bridge_url_resource[_this.getActualQualityLevel()],
                                    type: 'application/x-mpegURL'
                                });
                            },300)

                        });
                    }else if(!_this.playerBridge.paused()){
                        _this.playerBridge.pause();
                    }


                }
                if(_this.state.isIphone && _this.state.playerInfo.clipState==='play'){
                    playerHelper.hideLoading()
                }
                if(isNaN(_this.state.playerInfo.duration)){
                    _this.setPlayerInfoState({duration:this.duration()});
                }

                const duration = this.duration();
                const percentage = parseFloat((currentTime/duration)*100).toFixed(3);
                if(currentTime>0 && _this.state.loadingText!==_this.state.lang.general.loading_text_playing){
                    _this.setState({
                        loadingText:_this.state.lang.general.loading_text_playing
                    })
                }
                if(_this.state.playerInfo.bridgeState==='prebuffer'){
                    const diff = duration-currentTime;
                    if (diff < 0.1){
                        if (!_this.checkVideoBuffer.checkFour){
                            _this.changeCheckVideoBuffer('checkFour',true);
                        }
                    }else if (diff < 0.4){
                        if (!_this.checkVideoBuffer.checkThree){
                            _this.changeCheckVideoBuffer('checkThree',true);
                            _this.playerBridge.play();
                            _this.fadeInPlayerBridgeVolume();
                        }
                    }else if (diff < 0.6){
                        if (!_this.checkVideoBuffer.checkFive){
                            _this.changeCheckVideoBuffer('checkFive',true);
                            _this.fadeOutPlayerVolume();
                        }
                    }else if (diff < 1){
                        if (!_this.checkVideoBuffer.checkTwo){
                            _this.changeCheckVideoBuffer('checkTwo',true);
                            _this.playerBridge.currentTime(0);
                            _this.playerBridge.pause();
                        }
                    }else if (diff < 2){
                        if (!_this.checkVideoBuffer.checkOne){
                            _this.changeCheckVideoBuffer('checkOne',true);
                            if(!_this.state.playerInfo.muted && _this.state.playerInfo.userGesture){
                                _this.playerBridge.volume(0);
                                _this.playerVolumeFadeBridge = 0;
                                _this.playerBridge.play();
                                _this.playerBridge.muted(true);
                            }else{
                                _this.playerBridge.muted(true);
                                _this.playerBridge.play();
                            }
                            // player.play();
                        }
                    }
                }
                if(!_this.state.playerInfo.changeQualityState){
                    playerHelper.changeBarMovie(percentage);
                }
                _this.toggleBanner(currentTime,'vertical');
                _this.toggleBanner(currentTime);
            });
            this.on("canplaythrough", function(){
                if(_this.state.playerInfo.currentTime!==0 && _this.state.isIphone && _this.state.playerInfo.changeQualityState){
                    _this.player.currentTime(_this.state.playerInfo.currentTime)
                }
            });
            this.on("canplay", function(){
            });
            this.on("waiting",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.showLoading();
            });
            this.on("playing",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.hideLoading();
            });
            this.on("play",function(){
                if(_this.state.playerInfo.clipState==='play') playerHelper.hideLoading();
            });
            this.on("error",function(e){
                // Sentry.captureException(_this.player.error());
            });
            _this.setElmState({
                playPauseButton:pauseButton
            })

        });
    }

    playerEventBridge(){
        const _this = this;
        this.playerBridge.ready(function() {
            //event on metadata loaded
            this.on('loadedmetadata',function(){
                logger('Player Bridge Load Metadata');
            });
            this.on('loadeddata',function(){});
            this.on('ended',function(){
                playerHelper.resetBarMovie();
                playerHelper.resetBarBridge();
                logger('Player Bridge Ended','');
                _this.setElmState({
                    isPause:false,
                    showPausePoster:false
                },()=>{
                    logger('Player Bridge Ended','play next movie clip movie clip');
                    _this.playNextMovie(_this.state.moviePlot[_this.state.playerInfo.nextIndexMovie].id,true,false);
                })
            });
            this.on('timeupdate',function(){
                if(_this.state.playerInfo.bridgeState==='play'){
                    playerHelper.hideLoading();
                }
                const currTime = this.currentTime();

                const duration = this.duration();
                if(isNaN(_this.state.playerInfo.duration)){
                    _this.setPlayerInfoState({
                        duration:this.duration()
                    });
                }
                if(currTime>0 && _this.state.loadingText!==_this.state.lang.general.loading_text_playing){
                    _this.setState({
                        loadingText:_this.state.lang.general.loading_text_playing
                    })
                }
                if(_this.state.playerInfo.bridgeState==='play'){
                    const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.currentIndexMovie];
                    playerHelper.calculateWatchTimer(_this.state.watch_uid,moviePlayed.bridge_id,currTime,_this);
                    window.parent.postMessage("player:timeupdate:" + currTime,"*");
                    const percentage = parseFloat((currTime/(duration-voidBridgeTime))*100).toFixed(3);
                    playerHelper.changeBarBridge(percentage)
                    _this.toggleBanner(currTime);
                    if(!_this.state.playerInfo.lockedOpt && (duration-currTime)<=4 && duration-currTime>=0.5){
                        const idxMovie = _this.state.moviePlot[_this.state.playerInfo.nextIndexMovie].id;
                        const listOptBridge = document.querySelectorAll('.bridge-option-item');
                        listOptBridge.forEach((item,index)=>{
                            if(!item.classList.contains(`opt-${idxMovie}`)) {
                                item.style.display='none';
                            }
                        });
                        let opt =  document.querySelector(`.opt-${idxMovie}`);
                        if(!opt.classList.contains('active')){
                            opt.classList.add('active');
                        }
                        opt.classList.add('slide-up-fade-in-fast');
                        _this.setPlayerInfoState({
                            lockedOpt:true
                        },()=>{
                            const moviePlayed = _this.state.moviePlot[_this.state.playerInfo.nextIndexMovie];
                            _this.player.src({
                                src: moviePlayed.url_resource.auto,
                                type: 'application/x-mpegURL'
                            });
                            const orientation = !_this.state.is_potrait && _this.state.inFrame?'landscape':_this.state.orientation;
                            const selectedClass = orientation==='landscape'?'blink-border':'blink';
                            setTimeout(()=>{
                                if(orientation==='landscape'){
                                    opt =  document.querySelector(`.opt-${idxMovie} a`);
                                }
                                if(opt!=null){
                                    if(!opt.classList.contains(selectedClass)){
                                        opt.classList.remove(selectedClass);
                                    }
                                    opt.classList.add(selectedClass);
                                }

                                setTimeout(()=>{
                                    const bridgeControl = document.getElementById('bridge-control');
                                    bridgeControl.classList.remove('slide-up-fade-in');
                                    bridgeControl.classList.add('slide-up-fade-out');
                                },500);
                            },1200);
                        })
                    }
                    const diff = duration-currTime;
                    if (diff < 0.1){
                        if (!_this.checkVideoBuffer.checkFour){
                            _this.changeCheckVideoBuffer('checkFour',true);
                        }
                    }else if (diff < 0.4){
                        if (!_this.checkVideoBuffer.checkThree){
                            _this.changeCheckVideoBuffer('checkThree',true);
                            _this.player.play();
                            _this.fadeInPlayerClipVolume();
                        }
                    }else if (diff < 0.6){
                        if (!_this.checkVideoBuffer.checkFive){
                            _this.changeCheckVideoBuffer('checkFive',true);
                            _this.fadeOutPlayerBridgeVolume();
                        }
                    }else if (diff < 1){
                        if (!_this.checkVideoBuffer.checkTwo){
                            _this.changeCheckVideoBuffer('checkTwo',true);
                            _this.player.currentTime(0);
                            _this.player.pause();
                        }
                    }else if (diff < 2){
                        if (!_this.checkVideoBuffer.checkOne){
                            _this.changeCheckVideoBuffer('checkOne',true);
                            _this.player.volume(0);
                            _this.playerVolumeFadeClip = 0;
                            _this.player.play();
                            _this.player.muted(true);
                        }
                    }
                }

            });
            this.on("waiting",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.showLoading();
            });
            this.on("playing",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.hideLoading();
            });
            this.on("play",function(){
                if(_this.state.playerInfo.bridgeState==='play') playerHelper.hideLoading();
            });
            this.on("error",function(e){
                // Sentry.captureException(_this.playerBridge.error());
            });
        });
    }
    //video js handler
    initialEventPlayer(){
        this.playerEventClip();
        this.playerEventBridge();
    }


    fadeInPlayerBridgeVolume(){
        if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
            if(this.playerBridge.volume() <= 1){
                this.playerVolumeFadeBridge += 0.1;
                this.playerBridge.volume(this.playerVolumeFadeBridge);
                if (this.playerVolumeFadeBridge > 0){
                    this.playerBridge.muted(false);
                }
                if (this.playerVolumeFadeBridge <= 1){
                    setTimeout(()=>{this.fadeInPlayerBridgeVolume()}, 1);
                }
            }
        }

    }

    fadeOutPlayerVolume(){
        if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
            if(this.player.volume() > 0){
                this.playerVolumeFadeClip -= 0.1;
                this.player.volume(this.playerVolumeFadeClip);

                if (this.playerVolumeFadeClip === 0){
                    this.player.muted(true);
                }
                if (this.playerVolumeFadeClip > 0){
                    setTimeout(()=>{
                        this.fadeOutPlayerVolume()
                    }, 30);
                }
            }
        }
    }

    fadeInPlayerClipVolume(){
        if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
            if(this.player.volume() <= 1){
                this.playerVolumeFadeClip += 0.1;
                this.player.volume(this.playerVolumeFadeClip);
                if (this.playerVolumeFadeClip > 0){
                    this.player.muted(false);
                }
                if (this.playerVolumeFadeClip <= 1){
                    setTimeout(()=>{this.fadeInPlayerClipVolume()}, 1);
                }
            }
        }

    }

    fadeOutPlayerBridgeVolume(){
        if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
            if(this.playerBridge.volume() > 0){
                this.playerVolumeFadeBridge -= 0.1;
                this.playerBridge.volume(this.playerVolumeFadeBridge);

                if (this.playerVolumeFadeBridge === 0){
                    this.playerBridge.muted(true);
                }
                if (this.playerVolumeFadeBridge > 0){
                    setTimeout(()=>{
                        this.fadeOutPlayerBridgeVolume()
                    }, 30);
                }
            }
        }
    }

    playMovie(isChangeSource = true){
        logger('Play Video Index',this.state.playerInfo.currentIndexMovie);
        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
        tracking.playVideo(playedMovie.id,this.state.watch_uid,playedMovie.metadata.is_endplot,is_firstplot);

        this.loadedDataClip(()=> {
            if(isChangeSource){
                this.player.src({
                    src: playedMovie.url_resource.auto,
                    type: 'application/x-mpegURL'
                });
            }
            if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
                this.player.muted(false);
                this.player.volume(1);
            }
            this.player.play();
            this.resetCheckVideoBuffer();
            this.setBannerMedia();
        });
    }

    playBridge(){
        this.setState({loadingText:this.state.lang.general.loading_text_start});
        this.setElmState({
            isPause:false,
            showPausePoster:false
        },()=>{
            this.setPlayerInfoState({
                currentTime:0,
                bridgeState:'play',
                closedBanner:false,
                lockedOpt:false,
                clipState:'prebuffer'
            },()=>{
                logger('Player Bridge Load Data','set state show bridge control');
                this.setElmState({
                    showSettingContainer:true,
                    showVideoControl:false,
                    showBridgeControl:true,
                    showSettingButton:false,
                    showShareButton:false,
                    isOpenVideoControl:false,
                    isOpenSettingControl:false,
                    showPausePoster:false
                },()=>{
                    const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                    const is_endplot = playedMovie.metadata.is_endplot;
                    const is_firstplot = this.state.playerInfo.currentIndexMovie===0;
                    tracking.playVideo(playedMovie.bridge_id,this.state.watch_uid,is_endplot,false);
                    logger('Player Bridge Load Data','hide video control on bridge');
                    playerHelper.toggleSettingContainer(true);
                    playerHelper.toggleVideoControl(false);
                    clearTimeout(this.videoControlTimeoutObj);
                    logger('Player Bridge Load Data','clear text subtitle on bridge clip');
                    playerHelper.clearTextTrack(this.player);
                    this.setBannerMedia();
                    this.playerBridge.play();
                    this.resetCheckVideoBuffer();
                    if(!this.state.isIphone){
                        if(this.state.playerInfo.userGesture){
                            if(this.state.playerInfo.muted){
                                this.playerBridge.muted(true);
                            }else{
                                this.playerBridge.muted(false);
                                this.playerBridge.volume(1);
                            }
                        }else{
                            this.playerBridge.muted(true);
                        }
                    }else{
                        this.playerBridge.muted(this.state.playerInfo.muted);
                    }
                });
            })
        });
    }
    isLastEpisode(){
        const episodeCount = this.state.episode_list.length;
        if(episodeCount>0){
            const indexMovie = _.findIndex(this.state.episode_list, { "id": this.state.id})
            const lastEpisode = indexMovie+1===episodeCount;
            this.setPlayerInfoState({
                isLastEpisode:lastEpisode,
                nextEpisodeSlug:!lastEpisode?this.state.episode_list[indexMovie+1].slug:""
            },()=>{
                logger('Episode',this.state.playerInfo.isLastEpisode+'-'+this.state.playerInfo.nextEpisodeSlug)
            })
        }
    }
    playNextEpisode(slug=""){
        slug = slug!==""?slug:this.state.playerInfo.nextEpisodeSlug;
        this.setState({
            slug:slug,
            busy: true
        },()=>{
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                });
            })
            playerHelper.getMovieDetail(this.state.defaultLang.toLowerCase(),this.state.slug,this.state.client_id).then(response => {
                this.setState({
                    ...response.data.data,
                    busy:false
                },()=>{
                    playerHelper.isMoviePotrait = this.state.is_potrait;
                    this.setElmState({
                        showEpisodeList:false,
                        showPausePoster:false,
                        showFullscreenButton: this.state.nofs===false?(!this.state.isIphone && this.state.allow_fullscreen):false
                    },()=>{
                        this.setPlayerInfoState({
                            bridgeState:'play'
                        },()=>{
                            this.replayMovie(()=>{
                                if(this.state.is_series){
                                    this.isLastEpisode();
                                }
                            });
                        });

                    })
                })
            })
            .catch(error => {
                this.setState({showBlank:true});
                this.setState({busy: false})
            })
        })
    }
    //end videojs handler

    setElmState(data,callback=null){
        let elm = {...this.state.elm};
        _.forEach(data, function(value, key) {
            elm[key] = value;
        });
        this.setState({
            elm
        },callback);
    }

    setPlayerInfoState(data,callback = null){
        let playerInfo = {...this.state.playerInfo};
        _.forEach(data, function(value, key) {
            playerInfo[key] = value;
        });
        this.setState({
            playerInfo
        },callback);

    }

    setBridgeObjectState(data,callback = null){
        let bridgeObject = {...this.state.bridgeObject};
        _.forEach(data, function(value, key) {
            bridgeObject[key] = value;
        });
        this.setState({
            bridgeObject
        },callback);

    }
    //Handler Event
    handlerPlayPause(){
        if(!this.player.paused()){
            this.pauseMovie();
        }else{
            this.unPauseMovie();
        }
    }

    pauseMovie(){
        this.setElmState({
            showPausePoster:true,
            playPauseButton:playButton,
            splashButton:pauseSplash,
        },()=>{
            const orientation = !this.state.is_potrait && this.state.inFrame?'landscape':this.state.orientation;
            playerHelper.toggleSplash(this.state.isMobile,orientation,this.state.lang.general.video_paused);
            this.player.pause();
            playerHelper.sendWatchTimer(this,(data)=>{
                tracking.bulkWatchTime(data,(newData)=>{
                    playerHelper.putDataTrackWatchTimeFallback(newData);
                });
            })
            if(this.videoControlTimeoutObj!==null){
                clearTimeout(this.videoControlTimeoutObj);
            }
        });
    }

    unPauseMovie(){
        this.setElmState({
            showPausePoster:false,
            playPauseButton:pauseButton,
            splashButton:playSplash,
        },()=>{
            const orientation = !this.state.is_potrait && this.state.inFrame?'landscape':this.state.orientation;
            playerHelper.toggleSplash(this.state.isMobile,orientation,this.state.lang.general.video_played);
            this.player.play();
            this.setVideoControlTimeout();
        });
    }

    toggleMuteVolume(userGesture = true){
        this.setPlayerInfoState({
            muted:!this.state.playerInfo.muted,
        },()=>{
            let volumeText = this.state.playerInfo.muted?this.state.lang.setting.sound_off:this.state.lang.setting.sound_on;
            if(this.state.client_id==='maxstream'){
                volumeText = this.state.playerInfo.muted?'Mute':'Unmute';
            }
            this.setElmState({
                volumeText:volumeText,
                volumeButton:this.state.playerInfo.muted?volumeMute:volumeOn
            },()=>{
                this.player.muted(this.state.playerInfo.muted);
                if(!this.state.playerInfo.muted){
                    this.player.volume(1);
                }
                if(userGesture && !this.state.playerInfo.userGesture){
                    this.setPlayerInfoState({
                        userGesture:true
                    },()=>{
                        this.playerBridge.muted(false);
                        this.playerBridge.volume(0);
                        this.playerBridge.play();
                        setTimeout(()=>{
                            this.playerBridge.pause();
                            this.playerBridge.currentTime(0);
                        },100)
                    })
                }
            })
        })
    }

    getActualQualityLevel(){
        const moviePlayed = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let actualQualityLevel = 'auto';
        if(moviePlayed.url_resource[this.state.playerInfo.qualityLevel]!==undefined){
            actualQualityLevel = this.state.playerInfo.qualityLevel;
        }
        return actualQualityLevel;
    }

    replayMovie(callback=null){
        this.setPlayerInfoState({
            hasEnded:false,
            videoType:'movie',
            currentIndexMovie:0,
            nextIndexMovie:_.findIndex(this.state.moviePlot, { "id":this.state.moviePlot[0].default_option})
        },()=>{
            logger('replay movie current index',this.state.playerInfo.currentIndexMovie);
            this.setElmState({
                showEndPoster:false,
                playPauseButton:pauseButton
            },()=>{
                this.generateBridgeObject();
                this.playMovie();
                if(callback!==null)callback()
            })
        })
    }

    lastCheckpoint(is_end_movie=true){
        if(this.state.playerInfo.currentIndexMovie!==0) {
            const lastIndexMovie = this.state.playerInfo.currentIndexMovie;
            let currentIndexMovie = this.state.playerInfo.lastIndexMovie;
            let nextIndexMovie = _.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[currentIndexMovie].default_option});
            this.setPlayerInfoState({
                currentIndexMovie:currentIndexMovie,
                nextIndexMovie:nextIndexMovie,
                videoType:'bridge',
                hasEnded:false
            }, () => {
                this.setElmState({
                    showEndPoster:false,
                    showPausePoster:false
                },()=>{
                    this.player.pause();
                    playerHelper.sendWatchTimer(this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        })
                    })
                    if(!is_end_movie){
                        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                        this.playerBridge.src({
                            src: playedMovie.bridge_url_resource[this.getActualQualityLevel()],
                            type: 'application/x-mpegURL'
                        });
                    }else{
                        this.playerBridge.currentTime(0);
                    }
                    if(!this.state.playerInfo.muted && this.state.playerInfo.userGesture){
                        this.playerBridge.muted(false);
                        this.playerBridge.volume(1);
                    }
                    playerHelper.clearTextTrack(this.player);
                    this.generateBridgeObject();
                    this.playBridge()
                })
            })
        }
    }
    seeking(time){

        if(this.state.playerInfo.videoType==='movie'){
            const now = this.player.currentTime();
            const duration = this.player.duration();
            const diff = duration-now;
            if((diff>=0.5 && this.state.isIphone) || !this.state.isIphone){
                const seek = now+time;
                this.clearVideoControlTimeOut();
                this.setElmState({
                    splashButton:time<0?backwardSplash:forwardSplash
                },()=>{
                    const actionText = time<0?'-':'+';
                    const orientation = !this.state.is_potrait && this.state.inFrame?'landscape':this.state.orientation;
                    playerHelper.toggleSplash(this.state.isMobile,orientation,actionText+seekTime+' '+this.state.lang.general.seconds);
                    if(seek>duration && this.state.isIphone){
                        if(!this.player.paused()){
                            this.player.pause();
                        }
                        this.eventEndedClip();
                    }else{
                        this.player.currentTime(seek);
                    }
                    this.setVideoControlTimeout();
                    if(seek<=0){
                        this.lastCheckpoint(false);
                    }
                })
            }

        }
    }
    handleSeekSidebarRight(event) {
        this.handleDoubleClickSidebar(event,'right');
    }
    handleSeekSidebarLeft(event) {
        this.handleDoubleClickSidebar(event,'left');
    }
    handleDoubleClickSidebar(event,position){
        if(this.state.playerInfo.hasStart && !this.state.playerInfo.hasEnded && this.state.playerInfo.videoType==='movie'){
            if (!this._delayedClick) {
                this._delayedClick = _.debounce(this.singleClickSeekSide, 500);
            }
            if (this.clickedOnce) {
                this._delayedClick.cancel();
                this.clickedOnce = false;
                const seek = position==='right'?seekForward:seekBackward;
                this.seeking(seek);
            } else {
                this._delayedClick(event);
                this.clickedOnce = true;
            }
        }
    }

    singleClickSeekSide(event) {
        this.clickedOnce = undefined;
        if(!this.state.elm.isOpenVideoControl){
             this.toggleVideoControl();
        }

        if ((this.state.elm.showSettingContent || this.state.elm.showSubtitleContent || this.state.elm.showQualityContent) && this.state.elm.showPausePoster && this.player.paused()){
            this.setElmState({
                showSubtitleContent:false,
                showQualityContent:false,
                showSettingContent: false
            })
        }
    }

    handlerVideoControl(event){
        let doAction = true;
        if(_.has(event, 'target')){
            const targetClass = event.target.className;
            if(typeof targetClass.includes === 'function') {
                doAction = !targetClass.includes("btn-video-control");
            }
        }
        doAction = this.state.elm.showPausePoster?false:doAction;
        if(doAction && !this.clickedControl){
            this.toggleVideoControl(event);
            if(this.state.orientation==='landscape'){
                this.clickedControl = true;
                this.timeOutVideoControClick = setTimeout(()=>{
                    this.clickedControl = false;
                },500);
            }
        }

        if ((this.state.elm.showSettingContent || this.state.elm.showSubtitleContent || this.state.elm.showQualityContent) && this.state.elm.showPausePoster && this.player.paused()){
            this.setElmState({
                showSubtitleContent:false,
                showQualityContent:false,
                showSettingContent: false
            })
        }
    }
    toggleVideoControl(event){
        if(this.state.playerInfo.videoType==='movie'){
            this.setElmState({
                isOpenSettingControl:!this.state.elm.isOpenVideoControl,
                isOpenVideoControl:!this.state.elm.isOpenVideoControl,
            },()=>{
                this.hideAllSettingControl();
                playerHelper.toggleVideoControl(this.state.elm.isOpenVideoControl)
                playerHelper.toggleSettingContainer(this.state.elm.isOpenVideoControl)
                this.setVideoControlTimeout();
            })
        }
    }

    playNextMovie(id,isActive,isChangeSource = true){
        this.setPlayerInfoState({
            nextIndexMovie:_.findIndex(this.state.moviePlot,{id:id})
        },()=>{
            if(isActive){
                let lastIndexMovie = this.state.playerInfo.currentIndexMovie;
                let currentIndexMovie = this.state.playerInfo.nextIndexMovie;
                let nextIndexMovie = this.state.moviePlot[currentIndexMovie].default_option!==undefined?_.findIndex(this.state.moviePlot, { "id": this.state.moviePlot[currentIndexMovie].default_option}):0;
                this.setPlayerInfoState({
                    lastIndexMovie:lastIndexMovie,
                    currentIndexMovie:currentIndexMovie,
                    nextIndexMovie:nextIndexMovie,
                    videoType:'movie'
                },()=>{
                    playerHelper.sendWatchTimer(this,(data)=>{
                        tracking.bulkWatchTime(data,(newData)=>{
                            playerHelper.putDataTrackWatchTimeFallback(newData);
                        })
                    })
                    this.setElmState({
                        showBridgeControl:false
                    },()=>{
                        this.generateBridgeObject();
                        this.playMovie(isChangeSource);
                    })
                });
            }
        })
    }

    handlerFullscreen(){
        this.setElmState({
            isFullscreen:!this.state.elm.isFullscreen
        },()=>{
            playerHelper.adjustFullscreen(this.state.elm.isFullscreen,this.state.isMobile,this.player,
                this.state.playerInfo.qualityLevel,this.state.bd,this.state.inFrame,this.state.is_potrait);
        })
    }
    handlerShare(){
        this.setElmState({
            showShareControl:!this.state.elm.showShareControl
        },()=>{
            if(!this.state.elm.showEndPoster){
                if(this.state.elm.showShareControl){
                    this.pauseMovie();
                }
            }
        });
    }
    handlerSettingControl(){
        this.setElmState({
            showSettingContent:!this.state.elm.showSettingContent
        },()=>{
            if(this.state.elm.showSettingContent){
                clearTimeout(this.videoControlTimeoutObj);
            }else{
                if(!this.state.elm.showPausePoster){
                    this.setVideoControlTimeout();
                }
            }
            this.setElmState({
                showSubtitleContent:false,
                showQualityContent:false
            })
        });
    }
    handlerEpisodeList(){
        this.setElmState({
            showEpisodeList:!this.state.elm.showEpisodeList
        })
    }
    //End Handler Event

    //action
    generateBridgeObject(){
        this.setBridgeObjectState({
            bridgeQuestion:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].title,
            bridgeOption:this.state.moviePlot[this.state.playerInfo.currentIndexMovie].option
        },()=>{
            const listOptBridge = document.querySelectorAll('.bridge-option-item');
            listOptBridge.forEach((item,index)=>{
                item.style.display='flex';
            });
        });
    }
    setVideoControlTimeout(){
        if(!this.state.elm.showPausePoster){
            this.clearVideoControlTimeOut();
            let _this = this;
            this.videoControlTimeoutObj = setTimeout(function () {
                if(_this.state.elm.showVideoControl && _this.state.playerInfo.videoType==='movie'){
                    playerHelper.toggleVideoControl(false);
                    playerHelper.toggleSettingContainer(false);
                    _this.setElmState({
                        isOpenVideoControl:false,
                        isOpenSettingControl:false
                    })
                }
            },idleTimeVideoControl*1000);
        }

    }

    clearVideoControlTimeOut(){
        if(this.videoControlTimeoutObj!==null){
            clearTimeout(this.videoControlTimeoutObj);
        }
    }
    changeQualityLevels(index,currentTime=null){
        const moviePlayed = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let actualQualityLevel = index;
        if(moviePlayed.url_resource[actualQualityLevel]===undefined){
            actualQualityLevel = 'auto';
        }
        this.setPlayerInfoState({
            qualityLevel:actualQualityLevel,
            qualityLevelLabel:mapTextQuality(index,this.state.lang.setting),
            currentTime:currentTime===null?this.player.currentTime():currentTime,
            changeQualityState:this.state.isIphone
        },()=>{
            this.playerBridge.src({
                src: moviePlayed.bridge_url_resource[actualQualityLevel],
                type: 'application/x-mpegURL'
            });
            if(this.state.isIphone){
                if(!this.state.elm.showPausePoster) {
                    playerHelper.elmSelector.playerBridge.style.display = 'none';
                    playerHelper.elmSelector.playerClip.style.display = 'none';
                }
                this.player.src({
                    src: moviePlayed.url_resource[actualQualityLevel],
                    type: 'application/x-mpegURL'
                });
            }else{
                playerHelper.setQualityLevel(this.player.qualityLevels(),actualQualityLevel);
            }
            if(!this.state.elm.showPausePoster){
                this.setVideoControlTimeout();
                if(this.state.isIphone){
                    this.player.play();
                }
                playerHelper.showLoading();
            }
            this.hideAllSettingControl();

        })
    }

    changeSubtitle(langId){
        if(langId!=='off'){
            playerHelper.reAssignTrackElement();
            let indexSubs = _.findIndex(this.state.playerInfo.subtitleList,{lang:langId});

            if(indexSubs===-1){
                return this.changeSubtitle('off');
            }
            // this.setState({busy: true})
            playerHelper.showLoading();
            playerHelper.fetchSubtitle(langId,this.state.playerInfo.subtitleList[indexSubs].file,this.player,
                this.state.playerInfo.currentIndexMovie,(langId)=>{
                    this.setState({
                        // busy:false,
                        bridgeDefaultLang:langId
                    },()=>{
                        playerHelper.hideLoading();
                        this.setPlayerInfoState({
                            subtitle:langId,
                            subtitleLabel:mapTextSubtitle(langId,this.state.lang.setting)
                        },()=>{this.setVideoControlTimeout();this.hideAllSettingControl()})
                    });
                });
        }else{
            this.setState({
                bridgeDefaultLang:this.state.primary_lang!==null?this.state.primary_lang:this.state.defaultLang
            },()=>{
                this.setPlayerInfoState({
                    subtitle:'off',
                    subtitleLabel:mapTextSubtitle('off',this.state.lang.setting)
                },()=>{
                    this.setVideoControlTimeout();
                    playerHelper.clearTextTrack(this.player);
                    this.hideAllSettingControl();
                })
            });
        }
    }

    hideAllSettingControl(){
        this.setElmState({
            showSettingContent:false,
            showQualityContent:false,
            showSubtitleContent:false
        });
    }
    //Banner
    setBannerMedia(){
        const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
        let media = null;
        if(this.state.playerInfo.hasEnded){
            media = playedMovie.metadata.media_endplot;
        }else{
            media = this.state.playerInfo.videoType==='movie'?playedMovie.metadata.media:(playedMovie.bridge_metadata!==null?playedMovie.bridge_metadata.media:null);
        }
        if(media!==null){
            let bannerInfo = {...this.state.bannerInfo};
            _.forEach(media, function(value, key) {
                bannerInfo[key] = value;
            });
            this.setState({
                bannerInfo
            },()=>{
                logger('Banner ada :',this.state.bannerInfo);
                this.resetBanner();
            });
        }else{
            this.setState({
                bannerInfo:defaultBannerState
            },()=>{
                logger('Banner ga ada :',this.state.bannerInfo);
                this.resetBanner();
            });
        }
    }
    toggleBanner(currTime = null,type='horizontal'){
        const haveBanner = type==='horizontal'?this.state.bannerInfo.banner.horizontal!==null:this.state.bannerInfo.banner.vertical!==null;
        if(haveBanner){
            let showBanner,closeBanner,timeStart,state = null;
            if(type==='vertical'){
                showBanner = this.state.elm.showBannerVertical;
                closeBanner = this.state.playerInfo.closedBannerV;
                timeStart = this.state.bannerInfo.banner.vertical_time.start;
                state = {showBannerVertical:true};
            }else{
                showBanner = this.state.elm.showBanner;
                closeBanner = this.state.playerInfo.closedBanner;
                timeStart = this.state.bannerInfo.banner.horizontal_time.start;
                state = {showBanner:true};
            }
            if(currTime>=timeStart && !showBanner && !closeBanner){
                this.setElmState(state,()=>{
                    const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                    let bannerType = 'hr';
                    if(type==='vertical'){
                        this.player.pause();
                        bannerType = 'vr';
                        if(scale>=2){
                            bannerType = 'vl';
                        }
                    }
                    tracking.bannerImpression(this.state.bannerInfo.id,
                        bannerType,
                        (this.state.playerInfo.videoType==='movie')?2:1,
                        (this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id
                    );
                })
            }
        }

    }
    hideBanner(){
        this.setPlayerInfoState({
            closedBanner:true
        },()=>this.setElmState({showBanner:false}))
    }
    resetBanner(){
        this.setPlayerInfoState({
            closedBanner:false
        },()=>this.setElmState({showBanner:false}))
    }
    //Banner Vertical
    hideBannerVertical(reset=false,callback=null){
        this.setPlayerInfoState({
            closedBannerV:!reset
        },()=>this.setElmState({showBannerVertical:false},callback))
    }

    showRotateElement(){
        if(this.forceShowRotate){
            return this.state.playerInfo.hasStart && !this.state.is_potrait && this.state.orientation==='potrait' && this.state.showRotateInfo;
        }else{
            return this.state.playerInfo.hasStart && this.state.isIphone && !this.state.is_potrait && this.state.orientation==='potrait' && this.state.showRotateInfo;
        }
    }

    render() {
        return (
            <div id="wrapper">
                {CONFIG.env!=='production'?<MetaTags>
                    <meta name="robots" content="noindex" />
                    <meta name="googlebot" content="noindex" />
                </MetaTags>:""}
                <div className="loader-container" style={{display:this.state.busy?'block':'none'}}>
                    <div className="spinners-container">
                        <div className="spinner-block">
                            <div className="spinner-eff spinner-eff-4">
                                <div className="bar bar-top"></div>
                                <div className="bar bar-right"></div>
                                <div className="bar bar-bottom"></div>
                                <div className="bar bar-left"></div>
                            </div>
                            <span>Loading</span>
                        </div>
                    </div>
                </div>
                <div className="show-blank" style={{display:(this.state.showBlank)?'block':'none'}}></div>
                <div id={this.state.isMobile?"mobile-player":"web-player"}>
                    {
                        this.showRotateElement()?
                        <div className={`iphone-rotate-info`} >
                            <div className={`info-content`} onClick={()=>{
                                this.setState({
                                    showRotateInfo:false
                                });
                            }} >
                                <p>{this.state.lang.general.rotate_info_title}</p>
                                <p>{this.state.lang.general.rotate_info_subtitle}</p>
                            </div>

                        </div>
                        :null
                    }
                    <InitPosterMobile
                        showInitialPoster={this.state.elm.showInitialPoster}
                        genre={this.state.genre}
                        title={this.state.title}
                        series_name={this.state.series_name}
                        duration={this.state.duration.long}
                        synopsis={this.state.synopsis}
                        initialPlay={() =>this.initialPlay()}
                        artwork={this.state.artwork.small}
                        start={this.state.lang.start}
                        thumbnail={this.state.thumbnail}
                        load={this.state.busy}
                        orientation={!this.state.is_potrait && this.state.inFrame?'landscape':this.state.orientation}
                    />
                    <PausePoster
                        showPausePoster={false}
                        genre={this.state.genre}
                        title={this.state.title}
                        series_name={this.state.series_name}
                        duration={this.state.duration.long}
                        synopsis={this.state.synopsis}
                    />
                    <EndPoster
                        showEndPoster={this.state.elm.showEndPoster}
                        title={this.state.title}
                        is_series={this.state.is_series}
                        isLastEpisode={this.state.playerInfo.isLastEpisode}
                        showIphoneImage={this.state.elm.showIphoneImage}
                        showAndroidImage={this.state.elm.showAndroidImage}
                        checkPointClick={() =>this.lastCheckpoint()}
                        replayMovie={() =>this.replayMovie()}
                        langText={this.state.lang.end_poster}
                        MovieId={this.state.id}
                        nextEpisode={() =>this.playNextEpisode()}
                        whiteLabel={this.state.whiteLabel}
                        isMobile = {this.state.isMobile}
                    />
                    <div className="seek-right" style={{
                        position:'fixed',
                        height:'100%',
                        width:'25%',
                        right:0,
                        cursor:'pointer',
                        zIndex:100
                    }} {...(this.isMobileOrigin)?{onTouchStart:this.handleSeekSidebarRight}:{onClick:this.handleSeekSidebarRight}} >&nbsp;</div>
                    <div className="seek-left" style={{
                        position:'fixed',
                        height:'100%',
                        width:'25%',
                        left:0,
                        cursor:'pointer',
                        zIndex:100
                    }}
                         {...(this.isMobileOrigin)?{onTouchStart:this.handleSeekSidebarLeft}:{onClick:this.handleSeekSidebarLeft}}
                    >&nbsp;</div>
                    <div className="splash">
                        <img src={this.state.elm.splashButton} alt="splash"/>
                    </div>
                    <div className="action-text-outer">
                        <span></span>
                    </div>
                    <div id="video-container" className="video-container" style={{
                        display:(this.state.elm.showVideoContainer)?'flex':'none',
                        cursor:'pointer'
                    }}
                         {...(this.isMobileOrigin)?{onTouchStart:this.handlerVideoControl}:{onClick:this.handlerVideoControl}}
                    >
                        <video id="my-video" className={`video-js`} playsInline poster={defaultPosterBlack} >
                            <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a web browser
                                that
                                <a rel="noopener noreferrer" href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5
                                    video</a>
                            </p>
                            <track kind='captions' src='' srcLang='' label='' id="track-subtitle"/>
                        </video>
                        <video id="my-videobridge" className="video-js" playsInline poster={defaultPosterBlack} >
                            <p className="vjs-no-js">
                                To view this video please enable JavaScript, and consider upgrading to a web browser
                                that
                                <a rel="noopener noreferrer" href="https://videojs.com/html5-video-support/"
                                   target="_blank">supports HTML5
                                    video</a>
                            </p>
                            <track kind='captions' src='' srcLang='' label='' id="track-subtitle"/>
                        </video>
                    </div>
                    <div className="setting-control" id="setting-control" style={{
                        display:(this.state.elm.showSettingContainer)?'block':'none'
                    }}
                         {...(this.isMobileOrigin)?{onTouchStart:this.handlerVideoControl}:{onClick:this.handlerVideoControl}}
                    >
                        {!this.state.whiteLabel?<img alt="tado-logo" src={tadologoWhite} className="tado-logo-left setting-content"/>:''}
                        <a href="#fullscreen" style={{
                            display:(this.state.elm.showFullscreenButton)?'block':'none'
                        }} id="fullscreen-btn" onClick={this.handlerFullscreen}><img alt="fullscreen" src={fullscreenButton} className="setting-content btn-video-control"/></a>
                        <a href="#setting" id="setting" onClick={this.handlerSettingControl} style={{
                            display:(this.state.elm.showSettingButton)?'block':'none'
                        }}><img alt="setting" src={settingButton} className="setting-content btn-video-control" /></a>
                        <a href="#share" id="share" onClick={this.handlerShare} style={{
                            display:(this.state.elm.showShareButton && this.state.shareable)?'block':'none'
                        }} ><img alt="setting" src={shareButton} className="setting-content btn-video-control" /></a>
                        <a href="#elist" id="share" onClick={this.handlerEpisodeList} style={{
                            display:(this.state.is_series && this.state.playerInfo.hasEnded)?'block':'none'
                        }}><img alt="setting" src={EpisodeListSmall} className="setting-content btn-video-control" /></a>

                    </div>
                    <SettingContent
                        showSettingContent={this.state.elm.showSettingContent}
                        showQualityContent={this.state.elm.showQualityContent}
                        showSubtitleContent={this.state.elm.showSubtitleContent}
                        qualityLevelList={this.state.playerInfo.qualityLevelList}
                        subtitleList={this.state.playerInfo.subtitleList}
                        qualityLevel={this.state.playerInfo.qualityLevel}
                        subtitle={this.state.playerInfo.subtitle}
                        subtitleLabel={this.state.playerInfo.subtitleLabel}
                        qualityLevelLabel={this.state.playerInfo.qualityLevelLabel}
                        lang={this.state.lang.setting}
                        changeSubtitle={(lang)=>{
                            this.changeSubtitle(lang);
                        }}
                        changeQualityLevels={(index)=>{
                            this.changeQualityLevels(index);
                        }}
                        hideSettingWithTimeout={()=>{
                            this.hideAllSettingControl();
                            this.setVideoControlTimeout();
                        }}
                        hideAllSettingControl={()=>this.hideAllSettingControl()}
                        handlerQualityLevelContainer={()=>{
                            this.setElmState({
                                showSettingContent:false,
                                showSubtitleContent:false,
                                showQualityContent:true
                            });
                        }}
                        handlerSubtitleContainer={()=>{
                            this.setElmState({
                                showSettingContent:false,
                                showSubtitleContent:true,
                                showQualityContent:false
                            });
                        }}
                        handlerBackToSetting={()=>{
                            this.setElmState({
                                showSettingContent:true,
                                showSubtitleContent:false,
                                showQualityContent:false
                            });
                        }}
                    />
                    <div className="video-control" id="video-control" style={{
                        display:this.state.elm.showVideoControl?'flex':'none'
                    }}
                         {...(this.isMobileOrigin)?{onTouchStart:this.handlerVideoControl}:{onClick:this.handlerVideoControl}}
                    >
                        <div className="action-text">
                            <span></span>
                        </div>
                        <div className="control-bar">
                            <button className="backward btn-video-control" onClick={()=>this.seeking(seekBackward)}>
                                <img src={bwCircleButton} alt="logo" className="btn-video-control" />
                            </button>
                            <div className="progress-bar-container">
                                <svg className="progress-box" viewBox="0 0 120 120" >
                                    <circle className="progress__value" style={{
                                        fill:'none',
                                        stroke:'#FF3455',
                                        strokeWidth:5
                                    }}
                                            cx="60" cy="60" r="54" />
                                </svg>
                                <div className="progress-button"><a href="#playpause" id="play-movie" onClick={this.handlerPlayPause}>
                                    <img alt="play pause" src={this.state.elm.playPauseButton} className="btn-video-control"/></a>
                                </div>
                            </div>
                            <button className="forward btn-video-control" onClick={()=>this.seeking(seekForward)}>
                                <img src={ffCircleButton} alt="logo" className="btn-video-control" />
                            </button>
                        </div>
                        <div className="control-bar-volume">
                            <a href="#volume" style={{cursor:'pointer'}} onClick={()=>this.toggleMuteVolume()}>
                                <div className="control-bottom">
                                    <div className="control-img btn-video-control">
                                        <img src={this.state.elm.volumeButton} alt="logo" className="btn-video-control"/>
                                    </div>
                                    <div className="control-text btn-video-control">{this.state.elm.volumeText}</div>
                                </div>
                            </a>
                            <a href="#eps-list" onClick={this.handlerEpisodeList} style={{
                                display:(this.state.is_series)?'block':'none'
                            }}>
                                <div className="control-bottom">
                                    <div className="control-img btn-video-control">
                                        <img src={EpisodeListSmall} alt="logo" className="btn-video-control" />
                                    </div>
                                    <div className="control-text btn-video-control">{this.state.lang.series.episode_list}</div>
                                </div>
                            </a>
                            <a href="#next-eps" onClick={()=>{
                                if(!this.state.playerInfo.isLastEpisode){
                                    this.playNextEpisode();
                                }
                            }} style={{
                                display:(this.state.is_series)?'block':'none'
                            }}>
                                <div className="control-bottom">
                                    <div className="control-img btn-video-control">
                                        <img src={(this.state.playerInfo.isLastEpisode)?NextEpisodeDisSmall:NextEpisodeSmall} alt="logo" className="btn-video-control"/>
                                    </div>
                                    <div className="control-text" style={(this.state.playerInfo.isLastEpisode)?{color:'#b3b3b3'}:{}}>{this.state.lang.end_poster.next_episode}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <BridgeControl
                        title={this.state.title}
                        series={this.state.series_name}
                        genre={this.state.genre}
                        bridgeLang={this.state.bridgeDefaultLang}
                        showBridgeControl={this.state.elm.showBridgeControl}
                        bridgeOption={this.state.bridgeObject.bridgeOption}
                        bridgeQuestion={this.state.bridgeObject.bridgeQuestion}
                        playNextMovie={(id,isActive)=>this.playNextMovie(id,isActive)}
                        langText={this.state.lang.bridge}
                        orientation={this.state.orientation}
                        allowDbCLick={this.state.bridge_allow_skip}
                        trackLockedOption={()=>tracking.pxTrackDownloadVideoPlayer(this.state.title,this.state.series_name,this.state.genre,"locked_option")}
                        forceMobile={!this.isMobileOrigin}
                    />
                    <Share
                        showModal={this.state.elm.showShareControl}
                        handlerShare={()=>this.handlerShare()}
                        slug={this.state.slug}
                        langText = {this.state.lang}
                        isMobile = {this.state.isMobile}
                    />
                    <Banner
                        analyticStatus = {this.state.analytic}
                        showBanner={this.state.elm.showBanner}
                        type={this.state.playerInfo.videoType}
                        is_endplot={this.state.playerInfo.hasEnded}
                        media={this.state.bannerInfo}
                        closeBanner={(event,id,type,src)=>{
                            if(this.state.analytic){
                                const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                                if(event==='click'){
                                    tracking.bannerClick(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }else{
                                    tracking.bannerClose(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }
                            }
                            this.hideBanner()
                        }}
                        isMobile = {this.state.isMobile}
                    />
                    <BannerVertical
                        analyticStatus = {this.state.analytic}
                        showBanner={this.state.elm.showBannerVertical}
                        type={this.state.playerInfo.videoType}
                        is_endplot={this.state.playerInfo.hasEnded}
                        media={this.state.bannerInfo}
                        orientation={this.state.orientation}
                        closeBanner={(event,id,type,src)=>{
                            if(this.state.analytic){
                                const playedMovie = this.state.moviePlot[this.state.playerInfo.currentIndexMovie];
                                if(event==='click'){
                                    tracking.bannerClick(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }else{
                                    tracking.bannerClose(id,type,src,(this.state.playerInfo.videoType==='movie')?playedMovie.id:playedMovie.bridge_id);
                                }
                            }
                            this.hideBannerVertical(false,()=>this.player.play());
                        }}
                    />
                    <EpisodeList
                        showList={this.state.elm.showEpisodeList}
                        showingId={this.state.id}
                        episodeList={this.state.episode_list}
                        close={()=>{
                            this.handlerEpisodeList()
                        }}
                        playNextEpisode={(slug)=>{
                            this.playNextEpisode(slug);
                        }}
                        lang={this.state.lang.series}
                        isMobile = {this.state.isMobile}
                        orientation = {this.isMobileOrigin?this.state.orientation:'potrait'}
                    />
                    <LoadingVideo text={this.state.loadingText} />
                </div>
            </div>
        );
    }
}
export default MobilePlayer;
