/**
 * Created by kuyarawa on 01/08/18.
 */

const CONFIG = {
  env:'production',
  apiUrl: 'https://the-api-prod-new.tadotv.com/web',
  apiWebsiteUrl: 'https://the-api-prod-new.tadotv.com/website',
  apiTadoUrl: 'https://the-api-prod-new.tadotv.com/',
  appName1: 'TADO',
  appName2: 'Dashboard',
  appNameAbbr1: 'T',
  appNameAbbr2: 'D',
  hlsS3Url: 'https://s3-ap-southeast-1.amazonaws.com/tado-hls/',
  s3Url: 'https://s3.ap-southeast-1.amazonaws.com/tado-tv/',
  playstoreUrl:'https://play.google.com/store/apps/details?id=com.tado.tv',
  appstoreUrl:'https://itunes.apple.com/id/app/tadotv-interactive-video/id1439414985?mt=8',
  shareUrl:'https://share.tadotv.com/',
  logDebug:false,
  studioUrl: 'https://studio.tadotv.com/',
  baseUrl:'https://tadotv.com/',

  //apps flyer banner
  bannerSubDomain:'go',
  bannerTitle:'TADOtv - Interactive Video Content',
  bannerSubTitle:'Tonton video interaktif lainnya dengan mendownload aplikasi TADOtv',
  bannerOnelinkId:'3n3X',
  bannerMediaSource:'Web_Player',
  bannerCampaign:'Web_Player_Install',
  bannerAdset:'',
  bannerDeeplink:'',

  //facebook pixel
  pixelId:'1188028734695957',

  //MLI
  mli_id:69,
  mli_url:'https://www.majelislucuindonesia.com/news/18',
  sentry_dsn:'https://d4b466f5fb454099b43b277aad844699@log.tadotv.com/6',
}

export default CONFIG
